import { defineComponent } from "vue";
export default defineComponent({
    name: "BaseBadge",
    props: {
        success: {
            type: Boolean,
            default: true,
        },
        danger: {
            type: Boolean,
            default: false,
        },
    },
});
