import { __awaiter, __generator } from "tslib";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import BaseIcon from "@/components/BaseIcon.vue";
import { getCartList } from "@/core/helpers/custom";
import { useRoute, useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import useNotyf from "@/composable/useNotyf";
import { addProductToCart } from "@/api/CartApi";
import { hideModal } from "@/core/helpers/dom";
export default defineComponent({
    name: "ModalProductDetail",
    components: {
        BaseIcon: BaseIcon,
    },
    props: {
        product: { type: Object },
        fullProductRoute: {
            type: String,
            required: true,
        },
    },
    setup: function (props, _a) {
        var _this = this;
        var emit = _a.emit;
        var addToCartItem = ref({});
        var products = ref([]);
        var route = useRoute();
        var fullProductRoute = ref("");
        var store = useStore();
        var router = useRouter();
        var productDetailModalRef = ref();
        var isUserAuthenticated = computed(function () {
            return store.getters.isUserAuthenticated;
        });
        var notification = useNotyf();
        var loading = ref({
            main: false,
            relatedProduct: false,
            addToCart: false,
            gotoProduct: false,
        });
        var addToCart = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value.addToCart = true;
                        return [4 /*yield*/, addProductToCart(addToCartItem.value)
                                .then(function (res) {
                                var _a;
                                notification.success((_a = res.data.message) !== null && _a !== void 0 ? _a : "Add to cart successfully");
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        getCartList();
                        if (productDetailModalRef.value) {
                            hideModal(productDetailModalRef.value);
                        }
                        loading.value.addToCart = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var gotoProductDetail = function () {
            loading.value.gotoProduct = true;
            if (productDetailModalRef.value) {
                hideModal(productDetailModalRef.value);
            }
            setTimeout(function () {
                router.push({
                    name: "product-show",
                    params: { productId: addToCartItem.value.product_id },
                });
            }, 300);
            loading.value.gotoProduct = false;
        };
        watch(function () { return props.product; }, function (updated) {
            addToCartItem.value = {
                product_id: Number(updated === null || updated === void 0 ? void 0 : updated.id),
                quantity: 1,
                options: [],
            };
            setTimeout(function () {
                var _a;
                (_a = props.product) === null || _a === void 0 ? void 0 : _a.options.forEach(function (option, x) {
                    // addToCartItem.value.options[]
                    option.values.forEach(function (value) {
                        var optionObj = {
                            option_id: option.id,
                            option_value_id: value.id,
                            price: value.price_prefix ? value.price : value.price * -1,
                        };
                        addToCartItem.value.options[x] = optionObj;
                    });
                });
            }, 500);
        });
        var closeModal = function () {
            emit("closeModal");
        };
        onMounted(function () {
            var _a, _b;
            setCurrentPageBreadcrumbs("Product Details", ["Product"]);
            fullProductRoute.value =
                window.location.host + "/product/" + ((_a = props.product) === null || _a === void 0 ? void 0 : _a.id);
            addToCartItem.value = {
                product_id: Number((_b = props.product) === null || _b === void 0 ? void 0 : _b.id),
                quantity: 1,
                options: [],
            };
        });
        return {
            route: route,
            loading: loading,
            products: products,
            isUserAuthenticated: isUserAuthenticated,
            addToCart: addToCart,
            addToCartItem: addToCartItem,
            gotoProductDetail: gotoProductDetail,
            productDetailModalRef: productDetailModalRef,
            closeModal: closeModal,
        };
    },
});
